// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import {Application} from "@hotwired/stimulus"
import NestedForm from 'stimulus-rails-nested-form'
import Autocomplete from 'stimulus-autocomplete'
import DragController from "./drag_controller"
import ModalController from "./modal_controller.js"
import Flatpickr from 'stimulus-flatpickr'
import PopoverController from "./popover_controller"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

application.register('nested-form', NestedForm)
application.register('autocomplete', Autocomplete)
application.register("drag", DragController)
application.register("modal", ModalController)
application.register('flatpickr', Flatpickr)
application.register('popover', PopoverController)
