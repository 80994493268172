window.showFrequencyAndEndRepeat = function (value) {
    switch (value) {
        case 'Daily':
            $('#end_repeat').show();
            $('#duration').show();
            break;
        case 'Weekly':
            $('#end_repeat').show();
            $('#duration').show();
            break;
        case 'Monthly':
            $('#end_repeat').show();
            $('#duration').show();
            break;
        case 'Yearly':
            $('#end_repeat').show();
            $('#duration').show();
            break;
        default:
            $('#end_repeat').hide();
            $('#duration').hide();
    }
}
