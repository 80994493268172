document.addEventListener("turbo:load", handleTurboLoad);

function handleTurboLoad() {
    initializeJQuery();
}

function initializeJQuery() {
    jQuery(function () {
        handleTabChange();
        handleOfficeStatusCategoryChange();
        handleOfficeStatusChange();
    });
}

// Used for pagination using tabs to prevent tab window from switching to first tab
function handleTabChange() {
    var hash = window.location.hash;
    if (hash) {
        $('ul.nav a[href="' + hash + '"]').tab('show');
    }
    $('.nav-tabs a').click(function (e) {
        var scrollmem = $('body').scrollTop();
        $(this).tab('show');
        window.location.hash = this.hash;
        $('html,body').scrollTop(scrollmem);
    });
}

// Used for selecting Active/Inactive when choosing a category
function handleOfficeStatusCategoryChange() {
    var officeStatusCategory = $('#office_statuses_attributes_0_category');
    officeStatusCategory.change(function () {
        var activeStates = ["Active None", "Active Lead", "Active Prospect", "Active Referring Office", "Active House Account"];
        var inactiveStates = ["Inactive None", "Inactive Lead", "Inactive Dead Lead", "Inactive Lost"];
        var valSelected = officeStatusCategory.val();

        if (activeStates.includes(valSelected)) {
            $('#office_statuses_attributes_0_active_true').prop('checked', true).trigger("click");
        } else if (inactiveStates.includes(valSelected)) {
            $('#office_statuses_attributes_0_active_false').prop('checked', true).trigger("click");
        }
    });
}

// Used to set Status to None when switching between Active/Inactive
function handleOfficeStatusChange() {
    var officeStatusCategory = $('#office_statuses_attributes_0_category');
    $('#office_statuses_attributes_0_active_true').change(function () {
        if (this.checked) {
            officeStatusCategory.val('Active None');
        }
    });
    $('#office_statuses_attributes_0_active_false').change(function () {
        if (this.checked) {
            officeStatusCategory.val('Inactive None');
        }
    });
}