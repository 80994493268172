const ALERT_FADE_DURATION = 500;
const ALERT_REMOVE_DELAY = 300;
const AUTO_REMOVE_DELAY = 3000;

function autoRemoveAlert() {
    $(".alert").fadeTo(ALERT_FADE_DURATION, 0).fadeTo(ALERT_REMOVE_DELAY, 0, function () {
        $(this).remove();
    });
}

document.addEventListener("turbo:load", function () {
    window.setTimeout(autoRemoveAlert, AUTO_REMOVE_DELAY);
});