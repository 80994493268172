import {Controller} from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    initialize() {
        this.end = this.end.bind(this)
    }

    connect() {
        this.sortable = new Sortable(this.element, {
            animation: 150,
            // handle: '.fa-grip-horizontal',
            delay: 500,
            delayOnTouchOnly: true,
            touchStartThreshold: 3,
            onEnd: this.end
        })
    }

    disconnect() {
        this.sortable.destroy()
        this.sortable = undefined
    }

    end({item, newIndex}) {
        if (!item.dataset.dragUrl || !window._rails_loaded) return

        let param = 'position'
        let data = new FormData()
        data.append(param, newIndex + 1)

        Rails.ajax({
            url: item.dataset.dragUrl,
            type: 'PATCH',
            data: data
        })
    }
}
