const divs = [
    'export_visits', 'export_visits_by_call_type', 'export_newest_visits_call_type_and_rep',
    'export_offices', 'export_physicians', 'export_provider_profiles', 'export_visits_by_user',
    'export_office_audits', 'export_physician_audits', 'export_visit_audits', 'export_status_audits'
];

const DivManager = {
    visibleDivId: null,
    toggleVisibility(divId) {
        this.visibleDivId = (this.visibleDivId === divId) ? null : divId;
        this.hideNonVisibleDivs();
    },
    hideNonVisibleDivs() {
        divs.forEach((divId) => {
            const div = document.getElementById(divId);
            div.style.display = (this.visibleDivId === divId) ? "block" : "none";
        });
    }
}

window.toggleVisibility = DivManager.toggleVisibility.bind(DivManager);
window.hideNonVisibleDivs = DivManager.hideNonVisibleDivs.bind(DivManager);